import "./css/App.css";

import { Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import LandingPage from "./pages/landing.js";
import KoffersMap from "./pages/koffersMap.js";
import Camp5g from "./pages/5gCamp.js";
import TicketsOverview from "./pages/tickets/overview";
import ServicesOverview from "./pages/services/overview";
import ServicesMaintenance from "./pages/services/maintenance";
import ServicesContacts from "./pages/services/contacts";
import ServicesKoffers from "./pages/services/koffers";
import ServicesOveruse from "./pages/services/overuse";
import ServiceDetails from "./pages/services/serviceDetails";
import ServiceUsage from "./pages/services/serviceUsage";
import OrdersOverview from "./pages/orders/overview";
import OrdersDirtyOrders from "./pages/orders/dirtyOrders";
import AssetsDevices from "./pages/assets/devices";
import AssetsAddDevices from "./pages/assets/addDevices";
import AssetsUpdateDevices from "./pages/assets/updateDevices";
import AssetsSims from "./pages/assets/sims";
import AssetsAddSims from "./pages/assets/addSims";
import AssetsUpdateSims from "./pages/assets/updateSims";
import AdminSignalTest from "./pages/admin/signalTest";
import AdminSignalTestV2 from "./pages/admin/signalTestV2";
import AdminTerminal from "./pages/admin/terminal.js";
import LogsTables from "./pages/admin/logsTables";
import InventoryCheck from "./pages/admin/inventoryCheck";
import PoolsDataUsage from "./pages/admin/poolsDataUsage";
import PoolDataUsageDetails from "./pages/admin/poolDataUsageDetails";
import HardwareDetails from "./pages/assets/hardwareDetails";
import SimcardDetails from "./pages/assets/simcardDetails";
import PSQLEdit from "./pages/admin/PSQL edit";
import Navbar from "./components/navbar";
import TestPage from "./pages/invoicing/testPage.js";

function App() {
  useEffect(() => {
    document.title = "Geuzenet";
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        {["/kf", "/camp"].includes(useLocation().pathname) ? (
          ""
        ) : (
          <div className="navGclass">
            <Navbar />
          </div>
        )}
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/kf" element={<KoffersMap />} />{" "}
          <Route exact path="/camp" element={<Camp5g />} />
          <Route exact path="/InvoicingtestPage" element={<TestPage />} />
          <Route exact path="/TicketsOverview" element={<TicketsOverview />} />
          <Route
            exact
            path="/ServicesOverview"
            element={<ServicesOverview />}
          />
          <Route
            exact
            path="/ServicesMaintenance"
            element={<ServicesMaintenance />}
          />
          <Route
            exact
            path="/ServicesContacts"
            element={<ServicesContacts />}
          />
          <Route exact path="/ServicesKoffers" element={<ServicesKoffers />} />
          <Route exact path="/ServicesOveruse" element={<ServicesOveruse />} />
          <Route path="/serviceDetails/:id" element={<ServiceDetails />} />
          <Route path="/serviceUsage/:id" element={<ServiceUsage />} />
          <Route exact path="/OrdersOverview" element={<OrdersOverview />} />
          <Route
            exact
            path="/OrdersDirty orders"
            element={<OrdersDirtyOrders />}
          />
          <Route exact path="/AssetsDevices" element={<AssetsDevices />} />
          <Route
            exact
            path="/AssetsAdd devices"
            element={<AssetsAddDevices />}
          />
          <Route
            exact
            path="/AssetsUpdate devices"
            element={<AssetsUpdateDevices />}
          />
          <Route exact path="/AssetsSims" element={<AssetsSims />} />
          <Route exact path="/AssetsAdd sims" element={<AssetsAddSims />} />
          <Route
            exact
            path="/AssetsUpdate sims"
            element={<AssetsUpdateSims />}
          />
          <Route path="/hardwareDetails/:tag" element={<HardwareDetails />} />
          <Route path="/simcardsDetails/:tag" element={<SimcardDetails />} />
          <Route
            exact
            path="/AdminSignal test/:tag?"
            element={<AdminSignalTestV2 />}
          />
          <Route exact path="/AdminTerminal" element={<AdminTerminal />} />
          {/* <Route
            exact
            path="/AdminSignal testV2/:tag?"
            element={<AdminSignalTestV2 />}
          /> */}
          <Route exact path="/AdminLogs tables" element={<LogsTables />} />
          <Route
            exact
            path="/AdminInventory check"
            element={<InventoryCheck />}
          />
          <Route exact path="/AdminPools usage" element={<PoolsDataUsage />} />
          <Route exact path="/AdminPSQL edit" element={<PSQLEdit />} />
          <Route
            path="/poolUsageDetails/:id"
            element={<PoolDataUsageDetails />}
          />
        </Routes>
      </header>
      {/* <footer style={{ color: "blue", lineHeight: 10, padding: 20 }}></footer> */}
    </div>
  );
}

export default App;
