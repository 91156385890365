import "../../css/adminSignalTest.css";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import closeIcon from "../../close.png";
import { TerminalFrame } from "../../components/terminalFrame";
import { useNavigate } from "react-router-dom";
import logo from "../../geuzenetlog.png";
import {
  setCategories,
  getAppUser,
  setSignalButtonState,
  setSignalAntennaTag,
  setSignalTagInput,
  reduxInit,
  openTerminal,
  setTerminalUser,
  setTerminalTag,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
  selectButtonState,
  selectAntennaTag,
  selectTagInput,
  selectTerminal,
} from "../../store/selectors/navigation";
import {
  storeAntennaDetails,
  getAntennaDetails,
  getModemsTags,
  setSignalDownload,
  setSignalUpload,
  storeSignalData,
  getTerminalDevices,
} from "../../store/actions/admin";

import {
  selectAntennaDetails,
  selectAllModems,
  selectOnlineModems,
  selectUnsupported,
  selectTerminalDevices,
} from "../../store/selectors/admin";

const validUser = (user) =>
  user
    ? !(
        !user.userRoles.includes("user") &&
        !user.userRoles.includes("admin") &&
        !user.userRoles.includes("inst")
      )
    : false;
function AdminSubAdmin1() {
  const terminal = useSelector(selectTerminal);
  useEffect(() => {
    dispatch(reduxInit());
    dispatch(getTerminalDevices()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispatch = useDispatch();
  const termDevices = useSelector(selectTerminalDevices);
  return (
    <div>
      <div className="inputArea">
        <div className="infoLine">
          <div className="infoLineKey">{"Device tag: "}</div>
          <div className="infoLineValue">
            <input
              className="tagInputField"
              disabled={terminal.open}
              value={terminal.tag}
              onChange={(e) => {
                dispatch(
                  setTerminalTag(
                    e.target.value.replace(/\s+/g, "").toUpperCase()
                  )
                );
              }}
            />
          </div>
        </div>
        <div className="infoLine">
          <div className="infoLineKey">{"User: "}</div>
          <div className="infoLineValue">
            <input
              className="tagInputField"
              disabled={terminal.open}
              value={terminal.user}
              onChange={(e) => {
                dispatch(setTerminalUser(e.target.value.replace(/\s+/g, "")));
              }}
            />
          </div>
        </div>
        <div className="infoLine"></div>
      </div>
      <button
        className={
          !terminal.open &&
          terminal.user.replace(/\s+/g, "").length > 0 &&
          termDevices.allDevices.includes(terminal.tag)
            ? "serviceUsageButton"
            : "sdbInactive"
        }
        onClick={(e) => {
          if (!terminal.open) {
            dispatch(openTerminal());
          }
          // if (item.influxService) {
          //   console.log(
          //     `${item.id} - ${item.description ? item.description : ""}`
          //   );
          //   // if (item.unifi.site) {
          //   window.open(
          //     `https://monitoring.geuzenet.nl:3003/d/fdp1ra5h5z5kwa/service-overview?orgId=1&refresh=5s&var-allHost=All&var-allCustomer=All&var-allServiceId=All&var-serviceId=All&var-host=All&var-deviceType=All&var-latency_addr=All&var-description=${
          //       item.id
          //     } - ${item.description ? item.description : ""}`
          //   );
          //   // }
          // }
        }}
      >
        Connect
      </button>
      {terminal.open ? (
        <TerminalFrame
          url={`https://hub.geuzenet.nl/ssh?port=${
            termDevices.type4GModem.includes(terminal.tag)
              ? `4${terminal.tag.slice(4)}`
              : `1${terminal.tag.slice(4)}`
          }&&user=${terminal.user}`}
        />
      ) : (
        ""
      )}
      {/* {terminal.open ? (
        <div className="teminalMain">
          <iframe
            src={url}
            title="GZN Terminal"
            width="80%"
            height="750"
          ></iframe>
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
}
export default AdminSubAdmin1;
