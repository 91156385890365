const initialState = {
  campLoading: false,
  edit: false,
  hamburgerOpen: false,
  bigTableLines: 30,
  appUser: false,
  signalButtonState: false,
  signalAntennaTag: "",
  signalTagInput: "",
  tableFilters: {
    listOfDeliveries_status: ["OnGoing", "New", "Planned"],
  },
  invoicing: {
    generalInvoices: true,
    partnerFees: false,
    internetSuitcases: false,
    invoicingDate: "",
    singleInvoices: true,
    collectiveInvoices: false,
    allInvoicesIds: [],
    includedInvoicesIds: [],
    preSelSearch: "",
    generatingInvoices: false,
    invoiceIndex: 0,
    linesOrder: [],
  },
  terminal: {
    open: false,
    user: "",
    tag: "",
  },
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "nav/setCategories":
      return { ...state, ...action.payload };
    case "nav/setBigTableLines":
      return { ...state, bigTableLines: action.payload };
    case "nav/setMeUser":
      return { ...state, appUser: action.payload };
    case "nav/setSignalButtonState":
      return { ...state, signalButtonState: action.payload };
    case "nav/setSignalAntennaTag":
      return { ...state, signalAntennaTag: action.payload };
    case "nav/setSignalTagInput":
      return { ...state, signalTagInput: action.payload };
    case "nav/storeTableFilters":
      return { ...state, tableFilters: action.payload };
    case "nav/setInvoicingSteps":
      return { ...state, invoicing: action.payload };
    case "nav/setCampLoading":
      return { ...state, campLoading: action.payload };
    case "nav/openTerminal":
      return { ...state, terminal: { ...state.terminal, open: true } };
    case "nav/setTerminalUser":
      return {
        ...state,
        terminal: { ...state.terminal, user: action.payload },
      };
    case "nav/setTerminalTag":
      return {
        ...state,
        terminal: { ...state.terminal, tag: action.payload },
      };
    default:
      return state;
  }
}
