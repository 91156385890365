import React, { memo } from "react";

export const TerminalFrame = (props) => {
  const { url } = props;
  return (
    <div className="teminalMain">
      <iframe src={url} title="GZN Terminal" width="80%" height="750"></iframe>
    </div>
  );
};

export const MemoAssetsDatePicker = memo(TerminalFrame);
