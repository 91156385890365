import axios from "axios";
import { servicesInit } from "./services";
import { assetsInit } from "./assets";
import { adminInit } from "./admin";
import { exportTableInit } from "./tableXlsxExport";
const key = process.env.REACT_APP_API_KEY;

const setMeUser = (userData) => {
  return {
    type: "nav/setMeUser",
    payload: userData ? userData : null,
  };
};

export const setCategories = (main, sub, view) => {
  return {
    type: "nav/setCategories",
    payload: { menuMain: main, menuSub: sub, viewType: view },
  };
};
export const setBigTableLines = (linesAmount) => {
  return {
    type: "nav/setBigTableLines",
    payload: linesAmount,
  };
};

export const getAppUser = () => {
  let userData;
  return async (dispatch, getState) => {
    const response = await axios.get(`/.auth/me`);
    let appUser;
    let webcrmId = 0;
    let confluenceId = false;
    if (response.data.clientPrincipal) {
      appUser = await axios.get(
        `/api/sync?code=${key}&&type=checkUser&&user=${response.data.clientPrincipal.userDetails}`
      );
      webcrmId = appUser.data.webcrm_id;
      confluenceId = appUser.data.confluence_id;
    }
    userData = {
      userRoles: [],
      ...response.data.clientPrincipal,
      webcrmId: webcrmId,
      confluenceId: confluenceId,
    };
    dispatch(setMeUser(userData));
  };
};

export const setSignalButtonState = (state) => {
  return {
    type: "nav/setSignalButtonState",
    payload: state,
  };
};

export const setSignalAntennaTag = (tag) => {
  return {
    type: "nav/setSignalAntennaTag",
    payload: tag,
  };
};

export const setSignalTagInput = (tag) => {
  return {
    type: "nav/setSignalTagInput",
    payload: tag,
  };
};
const storeTablesFiltersNew = (filter) => {
  return {
    type: "nav/storeTableFilters",
    payload: filter,
  };
};
export const storeTablesFilters = (filter) => {
  return async (dispatch, getState) => {
    let filtersNow = getState().navigation.tableFilters;
    dispatch(
      storeTablesFiltersNew({
        ...filtersNow,
        ...filter,
      })
    );
  };
};

export const setInvoicingSteps = (invoicingSteps) => {
  return {
    type: "nav/setInvoicingSteps",
    payload: invoicingSteps,
  };
};

export const setCampLoading = (loading) => {
  console.log(loading);
  return {
    type: "nav/setCampLoading",
    payload: loading,
  };
};

export const openTerminal = () => {
  return {
    type: "nav/openTerminal",
  };
};
export const setTerminalUser = (user) => {
  return {
    type: "nav/setTerminalUser",
    payload: user,
  };
};
export const setTerminalTag = (user) => {
  return {
    type: "nav/setTerminalTag",
    payload: user,
  };
};

export const reduxInit = () => {
  return async (dispatch, getState) => {
    dispatch(servicesInit());
    dispatch(assetsInit());
    dispatch(adminInit());
    dispatch(exportTableInit());
  };
};
